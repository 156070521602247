.image-upload-root {
    width: 100%;
    .label {
        margin-bottom: 6px;
        padding-left: 8px;
        width: 100%;
        overflow: hidden;
    }
    .image-upload-bin {
        height: 150px;
        width: 100%;
        overflow: hidden;
        position: relative;        
        .delete-icon {
            position: absolute;
            top: 6px;
            right: 6px;
            z-index: 5;
            cursor: pointer;
            svg {
                height: 24px;
                width: 24px;
            }
        }
        .dropzone {
            width: 100%;
            height: 100%;
            overflow: hidden;
            background: var(--ipanel-button-background-color);
            border: 1px solid var(--ipanel-border);
            border-radius: 5px;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
            .pictureless{
                display: flex;
                flex-direction: column;
                height: 90%;
                text-align: center;
                justify-content: space-between;
                align-items: center;
                .text-drag-and-drop{
                    font-size: 16px;
                }
                .text-or{
                    font-size: 12px;
                }
                .text-supported{
                    font-size: 12px;
                    &.error{
                        box-shadow: 0px 4px 3px -1px var(--toastify-color-error);
                    }
                }
                .upload-picture-button{
                    border: unset;
                    border-radius: 50px;
                    padding: 5px 25px;
                }
                .svg-root{
                    width: 100%;
                    height: 32px;
                    svg{
                        .svgStroke{
                            padding: 6px;
                            filter: drop-shadow(0 0 2px var(--module-color));
                            stroke: var(--module-color);
                        }
                    }
                }
            }
        }
    }
    .drop-area {
        width: 100%;
        text-align: center;
    }
}