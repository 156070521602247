.catalog-editor-root {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;
  .item-editor-categories-root,
  .modifier-editor-group-root {
    display: flex;
    flex-direction: column;
    border-left: 1px solid var(--main-background-color);
    width: 240px;
    max-width: 240px;
    height: 100%;
    padding: 1em;
    overflow: hidden;
    background-color: var(--main-box-color);
    transition: all 1s;
    &.brands-editor {
      .category-list {
        height: calc(100% - 2em);
      }
    }
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 1em;
      .svg-root {
        cursor: pointer;
      }
      .btn {
        background-color: var(--module-color);
        color: white;
        border-radius: 10px;
        width: 80%;
        padding: 6px 0px;
      }
    }
    .filter {
      .category-order-actions {
        .btn {
          margin: 0 0.5em;
        }
      }
    }
    .category-list,
    .modifier-group-list {
      // height: calc(100% - 13em);
      display: flex;
      flex-direction: column;
      overflow: auto;
      position: relative;
      padding-right: 6px;
      .okeoke-loading-screen {
        border-radius: 5px;
      }
      &.edit-categories {
        height: calc(100% - 13em);
      }
      &.edit-order {
        height: calc(100% - 16em);
      }
      .message {
        font-size: 18px;
        text-align: center;
        text-decoration: underline;
        cursor: pointer;
      }
      .textField-root {
        margin-bottom: 0.5em;
      }
      .disabled-container {
        .category-container {
          opacity: 0.6;
        }
      }
      .actions {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn {
          margin: 0 4px;
        }
      }
      .category-outer-container {
        border: 1px solid var(--ipanel-border);
        border-radius: 5px;
        margin: 0 0.5em 0.5em 0;
        background-color: var(--main-background-color);
      }
      .category-container,
      .group-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border-radius: 5px;
        gap: 6px;
        .link-icon {
          cursor: pointer;
          margin: 0 6px;
        }
        .dnd-container {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 6px;
          svg {
            cursor: grab;
          }
        }
        .accordion-root {
          margin: unset;
          .accordion-header,
          .accordion-body {
            border: none;
            white-space: nowrap;
          }
          .accordion-body {
            width: auto;
          }
        }
        &.not-selected:hover {
          .accordion-root {
            .accordion-header {
              // background-color: rgba(0, 0, 0, 0.2);
              border-radius: 5px;
            }
          }
        }
        &.selected {
          .accordion-root {
            .accordion-header {
              background-color: var(--module-color);
              border-radius: 5px;
              color: #fff;
              white-space: nowrap;
            }
          }
        }
      }
      .category-container {
        .edit-category {
          margin: 2px 6px 0px;
          visibility: hidden;
        }
        &:hover {
          .edit-category {
            visibility: visible;
          }
        }
      }
      .sub-category {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .arrow-container {
          height: 18px;
          width: 18px;
          svg {
            height: 18px;
            width: 18px;
          }
        }
        .category-outer-container {
          width: calc(100% - 18px);
          border: none;
          margin: 0;
        }
      }
    }
  }
  .item-editor-categories-root.closed,
  .modifier-editor-group-root.closed {
    max-width: 0;
    padding: 0;
    transition: all 1s;
  }
  .item-editor-items-root,
  .modifier-editor-modifiers-root {
    width: calc(100% - 240px);
    display: flex;
    flex-direction: column;
    padding-left: 1em;
    overflow: auto;
    &.full-width {
      width: 100%;
    }
    .details {
      font-size: 16px;
      font-weight: 600;
      display: flex;
    }
    .view-actions {
      &.item-editor-items-actions,
      &.modifier-editor-modifiers-actions {
        margin-bottom: 0px;
      }
    }
    .item-editor-items-actions,
    .modifier-editor-modifiers-actions {
      padding-right: 18px;
      .actions-left {
        .selected-categ-name,
        .selected-group-name {
          min-width: 265px;
          height: 2em;
          display: flex;
          align-items: center;
        }
        .toggler {
          .svg-root {
            font-size: 16px;
            margin-right: 1em;
            cursor: pointer;
          }
        }
      }
    }
    .item-editor-items-content,
    .modifier-editor-modifiers-content {
      position: relative;
      height: calc(100% - 4em - 6px);
      border-radius: 5px;
      margin-top: 6px;
      .okeoke-loading-screen {
        border-top-left-radius: 5px;
      }
      .okeoke-data-table {
        box-shadow: none;
        border-radius: 0;
        border-top-left-radius: 5px;
        .data-table-inner {
          .data-table {
            .data-body {
              tr {
                &.error {
                  background-color: var(--button-red);
                  color: white;
                  td {
                    .svg-root {
                      svg {
                        .svgStroke {
                          stroke: white;
                        }
                        .svgFill {
                          fill: white;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.create-item-modal,
.create-modifier-modal {
  .item-fields,
  .modifier-fields {
    .textField-root {
      margin: 0.5em 0.5em 0 0;
    }
  }
}
.create-item-modal {
  .item-fields {
    display: flex;
    flex-direction: column;
  }
}
.selected-item-editor-root {
  .view-content {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    flex-wrap: wrap;
    overflow: auto;
  }
}
.item-settings-box {
  width: 360px;
  //max-height: 300px;
  padding: 1em;
  background: var(--main-box-color);
  margin: 0.5em 1em 0.5em 0;
  border-radius: 5px;
  position: relative;
  &.double-height {
    max-height: calc(600px + 1em);
  }
  .images-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: auto;
    width: 100%;
    &.images-container {
      .image-upload-root {
        .label {
          display: flex;
          justify-content: center;
        }
        .image-upload-bin {
          .dropzone {
            .uploaded-img {
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .box-title {
    opacity: 0.7;
    height: 2em;
  }
  .box-fields {
    gap: 0.5em;
    height: calc(100% - 2em);
    padding: 6px;
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    .fruit-img {
      background-color: #fff;
      border-radius: 5px;
    }
  }
  .color-picker-root {
    width: 80px;
    height: 3em;
    margin: 0.5em;
    overflow: hidden;
  }
  .okeokeDataTable {
    height: calc(100% - 2em);
    box-shadow: none;
    background: rgba(0, 0, 0, 0.09);
    .dataColumn.name {
      text-align: left;
    }
    .dataColumn.price {
      text-align: right;
    }
  }
  &.allergens {
    .box-fields {
      display: flex;
      align-content: flex-start;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  &.sizes {
    .box-fields {
      height: calc(100% - 4em);
      .size-row {
        display: flex;
        align-items: center;
        margin-bottom: 1em;
        height: 26px;
        width: 100%;
        .svg-container {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 26px;
          height: 26px;
          svg {
            height: 20px;
            cursor: pointer;
          }
        }
        .size-name {
          width: calc(100% - 160px);
        }
        .size-default {
          width: 26px;
          height: 26px;
          display: flex;
          justify-content: center;
          align-items: center;
          .circle-filled {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            background-color: var(--button-green);
            cursor: pointer;
          }
          .circle {
            width: 10px;
            height: 10px;
            border-radius: 100%;
            border: 2px solid var(--grey);
            cursor: pointer;
          }
        }
      }
    }
    .add-size-container {
      text-align: center;
    }
  }
  &.nutritional-values {
    .box-fields {
      display: flex;
      flex-wrap: wrap;
      .small-input-field {
        margin: 0.5em;
        .post-fix {
          padding-right: 8px;
          width: 8px;
        }
      }
    }
  }
  &.upsales,
  &.categories,
  &.modifier-groups {
    .box-fields {
      height: calc(100% - 4em);
      .item-container {
        margin: 0 0.5em 0.5em 0;
        border-radius: 5px;
        border: 1px solid var(--lightgrey2);
        padding: 3px 10px;
        height: 2em;
      }
    }
    .add-container {
      text-align: center;
    }
  }
  &.categories {
    .menu-reset-button {
      margin: 0 0.5em 0.5em 0;
      padding: 3px 10px;
    }
  }
}

.dark-mode {
  .allergen-button {
    .icon {
      svg {
        .svgBg {
          fill: #383940;
        }
      }
    }
  }
}

.allergen-button {
  padding: 4px;
  width: 85px;
  display: flex;
  flex-direction: column;
  user-select: none;
  cursor: pointer;
  .icon {
    width: 50px;
    height: 50px;
    margin: auto;
    margin-bottom: 3px;
    svg {
      max-width: 100%;
      max-height: 100%;
      .svgBg {
        fill: #e0e0e0;
      }
      .svgText {
        fill: #757575;
      }
    }
  }
  .text {
    font-size: 0.5em;
    text-align: center;
    text-transform: uppercase;
  }
}
.allergen-button.selected {
  .icon {
    svg {
      .svgBg {
        fill: var(--button-orange);
      }
      .svgText {
        fill: #fff;
      }
    }
  }
}
.modal.size-modal {
  .modal-content {
    min-width: 450px;
    background-color: var(--main-background-color);
  }
  .size-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    .base-data,
    .ntak-container,
    .integrations {
      background-color: var(--main-box-color);
      padding: 12px;
      border-radius: 5px;
      .title {
        font-weight: bold;
        margin-bottom: 12px;
      }
      .fields {
        align-items: flex-start;
        .textField-root {
          margin: 0 0.5em 0.5em 0;
          min-width: 250px;
        }
      }
    }
    .integrations {
      .fields {
        flex-direction: row;
      }
    }
  }
}

.ntak-modal {
  .modal-content {
    min-width: 450px;
  }
  .ntak-container {
    display: flex;
    align-items: flex-start;
    .textField-root {
      margin: 0 0.5em 0.5em 0;
    }
  }
  .unit-container {
    display: flex;
    align-items: flex-start;
    .textField-root {
      margin: 0 0.5em 0.5em 0;
    }
  }
}

.modal-body.category-modal {
  display: flex;
  flex-wrap: wrap;
  max-width: 800px;
  .okeoke-input-switch {
    width: 100%;
    .switch-container {
      width: 240px;
    }
  }
  .category-description {
    width: 580px;
  }
  .images-container {
    display: flex;
    gap: 12px;
  }
  .textField-root {
    margin: 12px 12px 12px 0;
  }
}
