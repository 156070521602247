.iPanelV2Ad-root {
  cursor: pointer;
  .text {
    font-weight: bold;
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradient 8s ease infinite;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    position: relative;
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
