.ipanel-v2-popup-root {
  display: flex;
  flex-direction: column;
  max-width: 550px;
  user-select: none;
  justify-content: center;
  place-self: center;
  .header {
    display: flex;
    justify-content: center;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    .text {
      font-weight: 500;
      margin-bottom: 1rem;
      font-size: 48px;
      background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
      background-size: 400% 400%;
      animation: gradient 8s ease infinite;
      background-clip: text;
      -webkit-background-clip: text;
      color: transparent;
      position: relative;
      @media (max-width: 600px) {
        font-size: 36px;
      }
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    .desc {
      display: flex;
      font-size: 22px;
      margin-bottom: 1.5rem;
      text-align: center;
      @media (max-width: 600px) {
        font-size: 16px;
      }
    }
    .app-icons {
      display: flex;
      justify-content: space-around;
      padding: 0px 20%;
    }
    .appdownload-text {
      display: flex;
      justify-content: center;
    }
    .button-card {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-self: center;
      border-radius: 8px;
      width: 250px;
      height: 45px;
      font-size: 16px;
      font-weight: 500;
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.25);
      margin-bottom: 1rem;
      cursor: pointer;
      animation: wobble-back 0.35s forwards;
      &:hover {
        animation: wobble-forth 0.35s forwards;
      }
      &.bubble {
        box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.45);
        width: 50px;
        height: 50px;
        border-radius: 50%;
        align-items: center;
        justify-content: center;
      }
      .text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex: 1 1 auto;
      }
      .svg-root {
        display: flex;
        height: 85%;
        width: 85%;
      }
    }
  }
}

@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@keyframes wobble-back {
  0% {
    transform: scale(1.02);
  }
  35% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes wobble-forth {
  0% {
    transform: scale(1);
  }
  35% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1.02);
  }
}
