.tab-selector-root {
    display: flex;
    width: 100%;
    margin-bottom: 0.5em;
    padding-bottom: 0.5em;
    overflow: auto;
    justify-content: space-around;
    .tab-select {
        cursor: pointer;
        margin-right: 0.5em;
        &.selected-tab {
            font-weight: 600;
            border-bottom: 1px solid var(--grey);
        }
        &.not-selected {
            letter-spacing: 0.1px;
            border-bottom: none;
        }
    }
}