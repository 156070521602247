.brand-locations-root {
  .view-content {
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
    overflow: auto;
  }
}

.brand-location-content {
  display: flex;
  flex-direction: column;
  .tab-selector-root {
    padding-bottom: 0;
    margin-bottom: 2px;
    .tab-select {
      padding: 6px 12px;
      margin: 0px 1px;
      background-color: var(--main-box-color);
      border-top-right-radius: 5px;
      border-top-left-radius: 5px;
      &.selected-tab {
        border-bottom: none;
        background-color: var(--module-color);
        color: white;
      }
    }
  }
  .active-tab {
    display: flex;
    flex: 1 1 auto;
    overflow: auto;
    padding: 0.5em;
    background-color: var(--main-box-color);
    border-radius: 5px;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    .location-notification-config, 
    .location-address-config,
    .location-fruit-config {
      display: flex;
      width: 100%;
      height: 100%;
      max-width: 600px;
      flex-wrap: wrap;
      align-content: flex-start;
      .input-switch-root {
        width: 260px;
        margin: 1em;
      }
      .textField-root {
        width: calc(50% - 2em);
        margin: 1em;
      }
    }
    .location-fruit-config {
      .apikey-field {
        width: 100%;
      }
    }
    .location-devices-config {
      padding: 1em;
      height: 100%;
      .pin-container {
        height: 2em;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

.item-editor-categories-root {
  .category-list {
    .enabled-container {
      display: flex;
      flex-direction: column;
      .location-button-container,
      .category-container {
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid var(--ipanel-border);
        font-weight: 600;
        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
        }
        &.selected {
          transition: all 0.3s;
          color: #fff;
          background-color: var(--module-color);
        }
      }
    }
  }
}
.location-editor-root {
  &.catalog-editor-root {
    .item-editor-categories-root {
      max-width: 300px;
      width: 300px;
    }
  }
  .item-editor-items-root {
    .item-editor-items-content {
      &.location-editor-content {
        position: unset;
        .selected-location {
          .btn.btn-yes {
            position: absolute;
            top: 12px;
            right: 12px;
          }
        }
      }
    }
  }
  .item-editor-categories-root {
    .category-list {
      .enabled-container {
        .location-button-container {
          padding: 16px 10px;
        }
      }
    }
  }
}

.location-basic-data-root {
  display: flex;
  flex-direction: column;
  min-width: 200px;
  max-width: 600px;
  padding: 0.25em;
  overflow: hidden;
  .location-block {
    display: flex;
    flex-direction: column;
    .block-name {
      display: flex;
      font-size: 20px;
      font-weight: 600;
    }
    .block-content {
      display: flex;
      &.column {
        flex-direction: column;
      }
      .row {
        .desc {
          font-weight: 600;
          min-width: 50%;
        }
      }
    }
  }
  .row {
    align-items: center;
    gap: 24px;
    width: 100%;
  }
  .col {
    display: flex;
    flex-direction: column;
    padding: 1em;
  }
  .device-data-box {
    overflow: hidden;
    .btn {
      margin: 8px 8px 0 0;
    }
  }
}
