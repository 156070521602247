.brand-tag-root {
  display: flex;
  flex: 0 1 auto;
  padding: 0.3em 0.5em;
  gap: 0.2em;
  align-self: flex-start;
  background-color: #f5f5f5;
  color: var(--ipanel-button-text-color);
  border-radius: 50px;
  border: solid 1px var();
  align-items: center;
  svg {
    height: 15px;
    width: 15px;
    .svgFill {
      fill: var(--ipanel-button-text-color);
    }
    .svgStroke {
      stroke: var(--ipanel-button-text-color);
    }
  }
  .brand-tag-text {
    place-self: center;
  }
  &.red {
    background-color: #fef2f2;
    color: #d9382e;
    .svgFill {
      fill: #d9382e;
    }
    .svgStroke {
      stroke: #d9382e;
    }
  }
  &.green {
    background-color: #f5ffec;
    color: #64ba18;
    .svgFill {
      fill: #64ba18;
    }
    .svgStroke {
      stroke: #64ba18;
    }
  }
  &.cyan {
    background-color: #f5ffec;
    color: #0f6959;
    .svgFill {
      fill: #0f6959;
    }
    .svgStroke {
      stroke: #0f6959;
    }
  }
  &.lightCyan {
    background-color: #f5ffec;
    color: #19a29f;
    .svgFill {
      fill: #19a29f;
    }
    .svgStroke {
      stroke: #19a29f;
    }
  }
  &.orange {
    background-color: #fff8f2;
    color: #ff8412;
    .svgFill {
      fill: #ff8412;
    }
    .svgStroke {
      stroke: #ff8412;
    }
  }
  &.purple {
    background-color: #f9f2ff;
    color: #985cd3;
    .svgFill {
      fill: #985cd3;
    }
    .svgStroke {
      stroke: #985cd3;
    }
  }
  &.yellow {
    background-color: #fffbeb;
    color: #f59e0b;
    .svgFill {
      fill: #f59e0b;
    }
    .svgStroke {
      stroke: #f59e0b;
    }
  }
  &.blue {
    background-color: #eff6ff;
    color: #2563eb;
    .svgFill {
      fill: #2563eb;
    }
    .svgStroke {
      stroke: #2563eb;
    }
  }
  &.lightBlue {
    background-color: #eff6ff;
    color: #2d99c4;
    .svgFill {
      fill: #2d99c4;
    }
    .svgStroke {
      stroke: #2d99c4;
    }
  }
  &.grey {
    background-color: #f4f4f5;
    color: #71717a;
    .svgFill {
      fill: #71717a;
    }
    .svgStroke {
      stroke: #71717a;
    }
  }  
  &.darkGrey {
    background-color: #f4f4f5;
    color: #383940;
    .svgFill {
      fill: #383940;
    }
    .svgStroke {
      stroke: #383940;
    }
  }
}

.dark-mode {
  .brand-tag-root {
    background-color: #3f3f46;
    &.red {
      background-color: #3f3f46;
      color: #f87171;
      svg {
        .svgFill {
          fill: #f87171;
        }
        .svgStroke {
          stroke: #f87171;
        }
      }
    }
    &.green {
      background-color: #3f3f46;
      color: #64ba18;
      .svgFill {
        fill: #64ba18;
      }
      .svgStroke {
        stroke: #64ba18;
      }
    }
    &.cyan {
      background-color: #3f3f46;
      color: #20d6b5;
      .svgFill {
        fill: #20d6b5;
      }
      .svgStroke {
        stroke: #20d6b5;
      }
    }
    &.lightCyan {
      background-color: #3f3f46;
      color: #19a29f;
      .svgFill {
        fill: #19a29f;
      }
      .svgStroke {
        stroke: #19a29f;
      }
    }
    &.orange {
      background-color: #3f3f46;
      color: #f59e0b;
      .svgFill {
        fill: #f59e0b;
      }
      .svgStroke {
        stroke: #f59e0b;
      }
    }
    &.purple {
      background-color: #3f3f46;
      color: #b379ec;
      .svgFill {
        fill: #b379ec;
      }
      .svgStroke {
        stroke: #b379ec;
      }
    }
    &.yellow {
      background-color: #3f3f46;
      color: #fbbf24;
      .svgFill {
        fill: #fbbf24;
      }
      .svgStroke {
        stroke: #fbbf24;
      }
    }
    &.blue {
      background-color: #1d4ed8;
      color: #93c5fd;
      .svgFill {
        fill: #93c5fd;
      }
      .svgStroke {
        stroke: #93c5fd;
      }
    }
    &.lightBlue {
      background-color: #3f3f46;
      color: #2d99c4;
      .svgFill {
        fill: #2d99c4;
      }
      .svgStroke {
        stroke: #2d99c4;
      }
    }
    &.grey {
      background-color: #3f3f46;
      color: #e4e4e7;
      .svgFill {
        fill: #e4e4e7;
      }
      .svgStroke {
        stroke: #e4e4e7;
      }
    }
    &.darkGrey {
      background-color: #3f3f46;
      color: #383940;
      .svgFill {
        fill: #383940;
      }
      .svgStroke {
        stroke: #383940;
      }
    }
  }
}
